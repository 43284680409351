// Helper methods and constants for Playwright

const PLAYWRIGHT_PREFIX = 'pw';

const PLAYWRIGHT_ID_CATEGORIES = {
  activity: 'actvy',
  activities: 'actvy',
  activityCard: 'actvy-card',
  application: 'appln',
  applications: 'appln',
  applicationTable: 'appln-list',
  applicationTableItem: 'appln-list-item',
  approval: 'apprv',
  approvals: 'apprv',
  button: 'btn',
  detailItemText: 'detail-item-text',
  image: 'img',
  link: 'link-to',
  list: 'list',
  logo: 'logo',
  menuDropDown: 'drop',
  profile: 'profl',
  tag: 'tag',
};

/**
 *
 * @param {String} category - loose, arbitrary category of element being labelled
 * @param {String} label - distinct identifier for this element category on this page
 * @returns {String} - kebab-case test ID suitable for use with Playwright
 */
const makePlaywrightTestId = (category = '', label = '') => {
  if (!(Object.values(PLAYWRIGHT_ID_CATEGORIES).includes(category))) {
    console.warn(`'${category}' is not a defined category value for generating Playwright test IDs. Using it, but consider using or defining a category in helpers/playwright.js.`);
  }
  return [PLAYWRIGHT_PREFIX, category, label].join(' ')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase()
    .replace(/[^-a-z0-9]/g, '');
};

export {
  makePlaywrightTestId,
  PLAYWRIGHT_ID_CATEGORIES
};
