import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/tabs/tabs.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui-labs/accordion/accordion.js';
import '@brightspace-ui-labs/accordion/accordion-collapse.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/button/button.js';

import './features-grid/features-grid.js';
import '../../../shared/components/general/app-link/app-link.js';
import '../../../shared/components/activities/activity-table/activity-table.js';
import '../../../shared/components/general/nova-side-panel/nova-side-panel.js';
import '../../../shared/components/general/nova-side-panel-item/nova-side-panel-item.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';

/**
 * The view for showing a list of tenants
 */
export default class ViewAdmin extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      _tenants: { type: Array, attribute: false },
      _admins: { type: Array },
      _providers: { type: Array },
      _employers: { type: Array },
      _selectedType: { type: String, attribute: false },
      _isSkillsLoading: { type: Boolean, attribute: false },
      _filteredSkills: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      selectStyles,
      inputLabelStyles,
      heading1Styles,
      heading2Styles,
      heading3Styles,
      tableStyles,
      css`
        :host {
          display: block;
        }

        d2l-tabs {
          max-width: 1170px;
          width: 90vw;
        }

        .tenant-list-item app-link {
          z-index: 1;
        }

        .add-button, .add-tenant {
          display: block;
          margin-bottom: 25px;
        }

        .d2l-heading-1 {
          margin-top: 0;
        }

        .create-tenant {
          align-items: center;
          column-gap: 10px;
          display: grid;
          grid-template-columns: auto 1fr;
        }

        .tenant-list-item {
          align-items: center;
          display: grid;
          grid-template-columns: 30% auto;
          width: 100%;
        }

        .select-wrapper {
          display: flex;
          flex-wrap: wrap;
          width: min-content;
        }

        .select-wrapper > label {
          width: 100%;
        }

        .d2l-loading-spinner {
          text-align: center;
        }
        .skill-filter {
          margin-bottom: 10px;
        }
`,
    ];
  }

  constructor() {
    super();
    this._tenants = [];
    this._providers = [];
    this._employers = [];
    this._admins = [];
    this._isSkillsLoading = false;
    this.skeleton = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this._tenants = await this.client.listTenants();
    this.skeleton = false;
  }

  updated(changedProperties) {
    if (changedProperties.has('_tenants')) {
      this._refreshTenantsList();
    }
  }

  _refreshTenantsList() {
    this._admins = this._tenants?.filter(tenant => tenant.type === 'admin');
    this._providers = this._tenants?.filter(tenant => tenant.type === 'provider');
    this._employers = this._tenants?.filter(tenant => tenant.type === 'employer');
    this.requestUpdate();
  }

  render() {
    if (this.session.loggedIn && this.session.tenant.type !== 'admin') {
      this.navigate('/');
    }

    return html`
      <nova-side-panel minimized .skeleton="${this.skeleton}">
        <nova-side-panel-item panel="activities" label=${this.localize('view-admin.activities')} icon="tier1:assignments">
          <h1 class="d2l-heading-1">${this.localize('view-admin.activities')}</h1>
          <app-link class="add-button" href="activities/add"><d2l-button>${this.localize('general.addActivity')}</d2l-button></app-link>
          <activity-table .providers=${this._providers}></activity-table>
        </nova-side-panel-item>

        <nova-side-panel-item-group
            label="${this.localize('view-admin.tenants')}"
            icon="tier1:group">
            <nova-side-panel-item panel="providers" label=${this.localize('view-admin.providers.heading1')}>
              <h1 class="d2l-heading-1">${this.localize('view-admin.providers.heading1')}</h1>
              <app-link class="add-tenant" href="tenants/add/provider">
                <d2l-button>${this.localize('view-admin.addProvider')}</d2l-button>
              </app-link>
              ${this.tenantListTemplate(this._providers)}
            </nova-side-panel-item>
            <nova-side-panel-item panel="employers" label=${this.localize('view-admin.employers.heading1')}>
              <h1 class="d2l-heading-1">${this.localize('view-admin.employers.heading1')}</h1>
              <app-link class="add-tenant" href="tenants/add/employer">
                <d2l-button>${this.localize('view-admin.addEmployer')}</d2l-button>
              </app-link>
              ${this.tenantListTemplate(this._employers)}
            </nova-side-panel-item>
            <nova-side-panel-item panel="admin" label=${this.localize('general.admin')}>
              <h1 class="d2l-heading-1">${this.localize('general.admin')}</h1>
              ${this.tenantListTemplate(this._admins)}
            </nova-side-panel-item>
          </nova-side-panel-item-group>

          <nova-side-panel-item panel="features" icon="tier1:tools" label=${this.localize('view-admin.features')}>
              <features-grid .tenants=${this._tenants}></features-grid>
            </nova-side-panel-item>
      </nova-side-panel>
    `;
  }
  tenantListTemplate(tenants) {
    tenants.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return html`
      <d2l-list>
      ${tenants.map(tenant => html`
        <d2l-list-item>
          <div class="tenant-list-item">
            ${tenant?.imageUrl ? html`
              <img height="25px" alt="${tenant.name}" src="${tenant.imageUrl}">
            ` : html`
              <span>${tenant.name}</span>
            `}
            <app-link d2l-link href="/tenants/${tenant.id}/edit"><div>${tenant.name}</div></app-link>
          </div>
        </d2l-list-item>
      `)}
      </d2l-list>
    `;
  }

  _updateSkillsFilter(e) {
    const filterValue = e.target.value;
    this._filteredSkills = this._skills.filter(skill => {
      if (skill.name.toLowerCase().includes(filterValue.toLowerCase())) return true;
      if (skill.id.toLowerCase().includes(filterValue.toLowerCase())) return true;
      if (skill.activityName.toLowerCase().includes(filterValue.toLowerCase())) return true;
      if (skill.activityId.toLowerCase().includes(filterValue.toLowerCase())) return true;
      if (skill.activityType.toLowerCase().includes(filterValue.toLowerCase())) return true;
      return false;
    });
  }

  get _skillsTableTemplate() {
    if (!this._skills || Object.keys(this._skills).length === 0) return nothing;
    return html`
      <d2l-input-text class="skill-filter" label="${this.localize('view-admin.filterSkills.label')}" @keyup="${this._updateSkillsFilter}"></d2l-input-text>
      <d2l-table-wrapper sticky-headers>
        <table class="d2l-table">
          <thead>
          <tr>
            <th>${this.localize('view-admin.filterSkills.table.skillId')}</th>
            <th>${this.localize('view-admin.filterSkills.table.skillName')}</th>
            <th>${this.localize('view-admin.filterSkills.table.activityName')}</th>
            <th>${this.localize('view-admin.filterSkills.table.activityId')}</th>
            <th>${this.localize('view-admin.filterSkills.table.activityType')}</th>
          </tr>
          </thead>
          <tbody>
          ${this._filteredSkills.map(skill => html`
            <tr>
              <td>${skill.id}</td>
              <td>${skill.name}</td>
              <td>${skill.activityName}</td>
              <td>${skill.activityId}</td>
              <td><d2l-status-indicator state="${skill.activityType === 'course' ? 'none' : 'default'}" text="${skill.activityType}" bold></d2l-status-indicator></td>
            </tr>
          `)}
          </tbody>
        </table>
      </d2l-table-wrapper>`;
  }

}

window.customElements.define('view-admin', ViewAdmin);
