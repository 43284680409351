import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';
import { heading1Styles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { v4 as Uuid4 } from 'uuid';

import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';

import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';

import '../../../../../shared/components/general/nova-permission-input/nova-permission-input.js';

import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFormMixin } from '../../../../../shared/mixins/nova-form-mixin/nova-form-mixin.js';

class ManageRole extends NovaFormMixin(LocalizeNova(RequesterMixin(SkeletonMixin(LitElement)))) {
  static get properties() {
    return {
      roleId: { type: String },
      _currentRole: { type: Object },
      action: { type: String },
    };
  }

  constructor() {
    super();
    this._currentRole = { roleId: Uuid4().toLowerCase(), assignedUsers: 0 };
    this.showNovaErrorSummary = true;
    this.hideFormErrorSummary = true;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.localize();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  static get styles() {
    return [
      heading1Styles,
      heading2Styles,
      inputLabelStyles,
      css`
        :host {
          display: block;
        }
        .page-description {
          margin-bottom: 30px;
        }
        .input-description {
          margin-bottom: 10px;
        }
        d2l-form {
          padding-bottom: 12px;
        }
        .input-wrapper {
          padding-bottom: 24px;
        }
        .list-wrapper {
          padding-bottom: 30px;
        }
        .category {
          padding: 10px 0;
        }
    `];
  }

  async updated(changedProperties) {
    if (changedProperties.has('roleId')) {
      this._currentRole = this.roleId ? await this.client.getRole(this.roleId) : {};
    }
  }

  get action() {
    return this.roleId ? 'edit' : 'add';
  }

  handleChange(e) {
    const { id, value } = e.target;
    this._currentRole = { ...this._currentRole, [id]: value };
  }

  get _form() {
    return this.shadowRoot.getElementById('form');
  }

  async submit() {
    const validForm = await this.isFormValidated();

    if (!validForm) return;
    const tenantId = this.session.user.tenantId;
    const { roleId, roleName, description, permissions } = this._currentRole;

    if (this.action === 'add') {
      this._currentRole = await this.client.createRole(tenantId, roleName, description, permissions);
    } else if (this.action === 'edit') {
      await this.client.updateUserRole(tenantId, roleId, roleName, description, permissions);
    }
    this.dispatchEvent(new CustomEvent('role-saved', {
      detail: { role: this._currentRole },
      bubbles: true,
      composed: true }
    ));
  }

  _handlePermissionChange(e) {
    const { permissions } = e.detail;
    this._currentRole.rolePermissions = [...permissions];
  }

  render() {
    return html`
      <div>
        <h1 class="d2l-heading-1">${this.localize(`${this.action}-role.title`)}</h1>
        <div class="page-description">${this.localize(`${this.action}-role.description`)}</div>

        <d2l-form id="form" @d2l-form-submit="${this.validate}" @change="${this.handleChange}">
          <div class="input-wrapper">
            <label class="d2l-input-label d2l-input-label-required" for="roleName">${this.localize('manage-role.field.roleName.label')}</label>
            <d2l-input-text
              id="roleName"
              label-hidden
              label="${this.localize('manage-role.field.roleName.label')}"
              value="${this._currentRole.roleName}"
              required
            ></d2l-input-text>
          </div>

          <div class="input-wrapper">
            <label class="d2l-input-label d2l-input-label-required" for="description">${this.localize('manage-role.field.roleDescription.label')}</label>
            <div class="input-description">${this.localize('manage-role.field.roleDescription.description')}</div>
            <d2l-input-textarea
              id="description"
              label-hidden
              value="${this._currentRole.description}"
              rows="3"
              label="${this.localize('manage-role.field.roleDescription.label')}"
              required
            ></d2l-input-textarea>
          </div>
          <div class="list-wrapper">
            <nova-permission-input
              @nova-permissions-changed="${this._handlePermissionChange}"
              .value="${this._currentRole.rolePermissions || []}"
              required
            ></nova-permission-input>
          </div>
          <d2l-button @click="${this.submit}" type="submit" primary>${this.localize('manage-role.submit')}</d2l-button>
        </d2l-form>
      </div>
    `;
  }
}

window.customElements.define('manage-role', ManageRole);
